import {useCallback, useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {Box, Chip, styled, Typography} from '@mui/material';

import useI18n from '../../i18n';
import {sx} from '../../themes';
import {useApp} from '../../App';
import {
    addResizeListener,
    Breadcrumbs,
    Diagrams,
    ErrorMessage,
    LightView,
    NetworkGraph,
    SearchInput,
    WhoisList,
} from '..';

import '../../plotly.css';


export const Analysis = () => {
    const _ = useI18n();
    const {request, setPending, signed} = useApp();
    const [params, setParams] = useSearchParams();
    const [navSize, setNavSize] = useState(0);
    const [graphProps, setGraphProps] = useState();
    const [query, setQuery] = useState();
    const [result, setResult] = useState();
    const navRef = useRef();

    const fetchGraph = !signed ? null : async (key, value) => {
        setPending(true);
        const response = await (await ((i = 0, f) => (f = () =>
            request('/graph/' + key, 'GET', {value})
                .then(response => new Promise((resolve, reject) =>
                    response.status === 504 && ++i < 10
                        ? setTimeout(reject, 1e3)
                        : resolve(response)
                ))
                .catch(f)
        )())()).json();
        response.error || (key === 'autnum' && (response.title = value));
        setGraphProps(response);
        setPending(false);
    };

    const search = useCallback(async query => {
        setPending(true);
        let params = {query};
        if (query) {
            const response = await request('/whois', 'GET', params);
            const {error, query, result} = await response.json();
            if (response.status === 200) {
                setResult(result);
                params = {query};
            } else {
                params = setResult({error});
            }
        }
        setPending(false);
        return params;
    }, [request, setPending]);

    useEffect(() => {
        setGraphProps(null);
        setQuery(null);
    }, [signed]);

    useEffect(() => {
        if (query != null) {
            return;
        }
        const q = params.get('query') || '';
        setQuery(q);
        search(q);
    }, [params, query, search]);

    useEffect(() => addResizeListener(() => setNavSize((nav =>
        nav.getBoundingClientRect().bottom +
        parseFloat(window.getComputedStyle(nav).marginBottom) * 2
    )(navRef.current))), []);

    const searchHandler = async () => {
        setResult(null);
        (params => params && setParams(params))(await search(query));
    };

    return (
        <LightView>
            <Breadcrumbs item ref={navRef}>
                {_('Analysis')}
            </Breadcrumbs>
            {graphProps
                ?
                    <GraphBox height={`calc(100vh - ${navSize}px)`}>
                        {graphProps.error
                            ?
                                <Box height={1}>
                                    <ErrorMessage p={4}>
                                        {graphProps.error}
                                    </ErrorMessage>
                                </Box>
                            :
                                graphProps.nodes
                                    ?
                                        <NetworkGraph
                                            fa2settings={{slowDown: 10}}
                                            iterations={5000}
                                            {...graphProps}
                                        />
                                    :
                                        <Diagrams {...graphProps} />
                        }
                        <StyledChip
                            label={_('Close')}
                            onDelete={() => setGraphProps(null)}
                        />
                    </GraphBox>
                :
                    <>
                        <SearchBox>
                            <SearchInput
                                onChange={setQuery}
                                onSearch={searchHandler}
                                value={query || ''}
                            />
                            {signed ? null :
                                <Typography color="common.white" mt={1}>
                                    {_('Search results are limited for unauthorized users')}
                                </Typography>
                            }
                        </SearchBox>
                        <StyledWhoisList
                            handler={fetchGraph}
                            query={query}
                            {...result}
                        />
                    </>
            }
        </LightView>
    );
};

const my = {mb: 7.75, mt: {xs: 4.75, sm: 0}};
const px = {px: {xs: 2, md: 4, max: 24}};

const GraphBox = styled(Box)(sx({
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 1,
    minHeight: 180,
    ...my,
}));

const SearchBox = styled(Box)(sx({
    backgroundColor: 'primary.main',
    borderRadius: 1 / 3,
    ...my,
    ...px,
    py: 4,
}));

const StyledChip = styled(Chip)({
    bottom: 'calc(100% - var(--sigma-controls-margin))',
    float: 'right',
    right: 'var(--sigma-controls-margin)',
});

const StyledWhoisList = styled(WhoisList)(({
    theme: {breakpoints: b, unstable_sx: sx},
}) => sx({
    minWidth: b.values.sm - 60,
    ...px,
}));
