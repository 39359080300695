import {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Grid, ThemeProvider} from '@mui/material';

import {light} from '../themes';
import {addResizeListener, imgUrl, LayoutItem} from '.';


/** @see {Grid} */
export const View = forwardRef(({center, children, ...props}, ref) => center
    ?
        <LayoutItem component="main" xs {...props} ref={ref}>
            <Grid container height={1}>
                <Grid item mx={18.25} my="auto" textAlign="center" xs>
                    {children}
                </Grid>
            </Grid>
        </LayoutItem>
    :
        <LayoutItem component="main" xs {...props} ref={ref}>
            {children}
        </LayoutItem>
);

View.propTypes = {
    center: PropTypes.bool,
};

/** @see {View} */
export const DarkView = ({style, ...props}) => {
    const [backgroundPositionX, setBackgroundPositionX] = useState();
    const ref = useRef();

    useEffect(() => addResizeListener(() =>
        setBackgroundPositionX(ref.current.offsetLeft - 125),
    ), []);

    return (
        <View style={{
            backgroundImage: `url(${imgUrl('rhombus.png')})`,
            backgroundPositionX,
            backgroundPositionY: 148,
            ...style,
        }} {...props} ref={ref} />
    );
};

/** @see {View} */
export const LightView = props =>
    <ThemeProvider theme={light}>
        <View {...props} />
    </ThemeProvider>
;
