import PropTypes from 'prop-types';


/** @see {HTMLImageElement} */
export const Image = ({cursor, style, ...props}) =>
    <img alt="" style={cursor ? {cursor, ...style} : style} {...props} />
;

Image.propTypes = {
    cursor: PropTypes.string,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
};
