import PropTypes from 'prop-types';

import {Typography} from '@mui/material';

import useI18n, {I18nDate} from '../i18n';


/** @see {Typography} */
export const ErrorMessage = props =>
    <Typography color="error" fontWeight="bold" {...props} />
;

/** @see {Typography} */
export const TypographyDate = ({date, ...props}) => {
    const _ = useI18n();

    return (
        <Typography
            variant="subtitle2"
            color="secondary"
            component="time"
            dateTime={date}
            {...props}
        >
            <I18nDate
                date={date}
                month="long"
                transform={s => s.replace(/\s*,\s*/, ` ${_('at')} `)}
            />
        </Typography>
    );
};

TypographyDate.propTypes = {
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
