import {createTheme, responsiveFontSizes} from '@mui/material';


export const sx = props => ({theme: t}) => t.unstable_sx(props);

export const dark = responsiveFontSizes(createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 790,
            lg: 995,
            xl: 1105,
            xxl: 1320,
            max: 1536,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                positionStatic: sx({
                    alignItems: 'flex-end',
                    p: 1,
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: sx({
                    font: 'inherit',
                    px: 4.75,
                    py: 1.5,
                    mb: .875,
                    whiteSpace: 'nowrap',
                }),
                outlined: sx({
                    fontSize: '.75rem',
                    lineHeight: 1.667,
                    mb: 6.125,
                }),
                startIcon: sx({
                    mr: 1.125,
                    '& img': {
                        maxHeight: 17,
                        maxWidth: 17,
                        pb: '2px',
                    },
                }),
            },
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: 'max',
            },
            styleOverrides: {
                root: ({theme: {breakpoints: b, unstable_sx: sx}}) => sx({
                    height: 1,
                    [b.down('xxl')]: {
                        maxWidth: b.values.xxl - 50,
                    },
                    [b.down('xl')]: {
                        maxWidth: b.values.xl - 50,
                    },
                }),
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'inherit',
            },
        },
        MuiGrid: {
            variants: [
                {
                    props: {
                        variant: 'article',
                    },
                    style: sx({
                        backgroundColor: 'common.white',
                        borderRadius: 1 / 3,
                        p: 3.125,
                    }),
                },
                {
                    props: {
                        variant: 'bullet',
                    },
                    style: sx({
                        borderColor: 'transparent',
                        borderRadius: 1 / 3,
                        borderStyle: 'solid',
                        borderWidth: {md: 1},
                        fontWeight: 500,
                        height: {md: 490},
                        lineHeight: 1.625,
                        overflow: {md: 'hidden'},
                        px: 5.625,
                        py: 4.375,
                        '&:hover': {
                            borderColor: {md: 'primary.main'},
                            '& h5': {
                                display: {md: 'none'},
                            },
                            '& p': {
                                display: {md: 'block'},
                            },
                        },
                        '& img': {
                            height: 48,
                            maxHeight: 48,
                            maxWidth: 48,
                            width: 48,
                            mb: 3.125,
                        },
                        '& p': {
                            display: {md: 'none'},
                            m: {md: 0},
                        },
                    }),
                },
                {
                    props: {
                        variant: 'footer',
                    },
                    style: sx({
                        borderTopColor: 'primary.main',
                        borderTopStyle: 'solid',
                        borderTopWidth: 5,
                        fontWeight: 400,
                        lineHeight: 2,
                        pt: 1.5,
                    }),
                },
                {
                    props: {
                        variant: 'form',
                    },
                    style: sx({
                        mx: 'auto',
                        width: 370,
                        '& .Mui-error': {
                            color: 'primary.main',
                        },
                        '& .MuiFormHelperText-root': {
                            fontSize: '.875rem',
                            lineHeight: 1.214,
                            mt: 2,
                            mx: 0,
                            p: 0,
                        },
                        '& .MuiOutlinedInput-root': {
                            color: 'common.white',
                            '& fieldset': {
                                borderColor: 'common.white',
                            },
                            '&:hover fieldset': {
                                borderColor: 'primary.main',
                            },
                        },
                        '& a': {
                            color: 'secondary.main',
                        },
                        '& a, & button': {
                            fontSize: '.875rem',
                        },
                        '& button': {
                            px: 4.25,
                            py: 2.125,
                            width: 1,
                        },
                    }),
                },
                {
                    props: {
                        variant: 'layout',
                    },
                    style: ({theme: {palette: {common, type}}}) => ({
                        backgroundColor:
                            type === 'dark' ? common.black : common.white,
                        color:
                            type === 'dark' ? common.white : common.black,
                    }),
                },
                {
                    props: {
                        variant: 'logo',
                    },
                    style: {
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontWeight: 300,
                        pt: '2px',
                    },
                },
                {
                    props: {
                        variant: 'whois',
                    },
                    style: sx({
                        fontSize: '.875rem',
                        fontWeight: 400,
                        lineHeight: 1.214,
                        pl: 1.625,
                    }),
                },
                {
                    props: {
                        component: 'footer',
                    },
                    style: sx({
                        fontSize: '.875rem',
                        lineHeight: 1.214,
                        pb: 4.125,
                        pt: 3.125,
                    }),
                },
                {
                    props: {
                        component: 'header',
                    },
                    style: sx({
                        alignItems: 'center',
                        fontSize: '.875rem',
                        lineHeight: 1.214,
                        py: 1.625,
                    }),
                },
                {
                    props: {
                        component: 'main',
                    },
                    style: sx({
                        backgroundColor: 'background.default',
                        backgroundRepeat: 'no-repeat',
                    }),
                },
                {
                    props: {
                        component: 'nav',
                    },
                    style: ({
                        theme: {breakpoints: b, palette: p, unstable_sx: sx,
                    }}) => sx({
                        borderBottomColor: p.divider,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        fontSize: '1.125rem',
                        fontWeight: 400,
                        lineHeight: 1.22,
                        mb: 4.75,
                        pb: 2.25,
                        pt: 2.5,
                        [b.down('sm')]: {
                            display: 'none',
                        },
                    }),
                },
            ],
        },
        MuiLink: {
            defaultProps: {
                color: 'inherit',
                underline: 'hover',
                whiteSpace: 'nowrap',
            },
        },
        MuiList: {
            variants: [
                {
                    props: {
                        variant: 'contacts',
                    },
                    style: sx({
                        fontSize: '.875rem',
                        fontWeight: 600,
                        lineHeight: 1.214,
                        mb: 16.25,
                        '& button': {
                            mt: 1.625,
                            p: 1.5,
                            '& img': {
                                maxHeight: 19,
                                maxWidth: 23,
                                mr: '2px',
                            },
                        },
                        '& button, & button:hover': {
                            backgroundColor: 'primary.main',
                        },
                        '& button:hover': {
                            opacity: .8,
                        },
                        '& div': {
                            mb: .875,
                            textAlign: 'center',
                        },
                    }),
                },
            ],
        },
        MuiListItemText: {
            defaultProps: {
                disableTypography: true,
            },
            styleOverrides: {
                dense: {
                    margin: 0,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                dense: sx({
                    font: 'inherit',
                    m: .5,
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: sx({
                    fontFamily: 'inherit',
                    lineHeight: 1.25,
                }),
                input: sx({
                    px: 3.5,
                    py: 1.75,
                }),
            },
            variants: [
                {
                    props: {
                        type: 'search',
                    },
                    style: sx({
                        backgroundColor: 'common.white',
                        '& input': {
                            px: 2.25,
                        },
                    }),
                },
            ],
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: 'inherit',
            },
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                },
            },
        },
    },
    palette: {
        type: 'dark',
        background: {
            default: '#2f2d36',
            paper: '#262525',
        },
        divider: '#6e6c73',
        primary: {
            main: '#cb6241',
        },
        secondary: {
            main: '#a8a6a6',
        },
    },
    typography: {
        h1: {
            fontSize: 70,
            fontWeight: 700,
            lineHeight: 1.214,
            marginBottom: '1.563rem',
        },
        h2: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: 1.467,
            marginBottom: '2.188rem',
            marginTop: '1.25rem',
        },
        h3: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: 1.233,
            marginBottom: '2.875rem',
            textAlign: 'center',
        },
        h4: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: .667,
            marginBottom: '5.25rem',
        },
        h5: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.25,
        },
        h6: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.5,
            marginTop: '.813rem',
        },
        subtitle: {
            fontSize: 70,
            fontWeight: 700,
            lineHeight: 1.214,
            marginLeft: '1rem',
        },
        subtitle1: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.429,
            paddingBottom: '5.313rem',
            paddingTop: '1.875rem',
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.214,
        },
        caption: {
            borderRadius: 10,
            fontSize: 20,
            fontWeight: 400,
            lineHeight: 1.2,
            padding: 13,
        },
        paragraph: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.429,
            paddingTop: '1.125rem',
            marginBottom: '3.125rem',
        },
    },
    shape: {
        borderRadius: 30,
    },
}));

export const light = createTheme(dark, {
    palette: {
        type: 'light',
        background: {
            default: '#f5f5f5',
            paper: '#eee',
        },
        divider: '#d9d9d9',
    },
});
