import {Parser} from "html-to-react";


export * from './Image';
export * from './List';
export * from './Logo';
export * from './Input';
export * from './RouteLink';

/**
 * @param {string} html
 * @return {React.ReactNode}
 */
export const parseHtml = (p => html => p.parse(html))(Parser());
