import {Grid, Typography} from '@mui/material';

import useI18n from '../../i18n';
import {Breadcrumbs, ContourLogo, DarkView} from '..';


export const Products = () => {
    const _ = useI18n();

    return (
        <DarkView>
            <Breadcrumbs>
                {_('Products & services')}
            </Breadcrumbs>
            <ContourLogo>
                <Typography variant="h1" mb={10}>
                    {_('Products & services')}
                </Typography>
                <DescGrid header={_('Products')} items={[
                    'bgp-watcher',
                    'bgp-siem',
                    'igp-watcher',
                    'igp-modeler',
                    'ip-locator',
                    'ip-box',
                ]} />
                <DescGrid header={_('Services')} items={[
                    'Development',
                    'Network research',
                    'RCA analysis',
                ]} />
            </ContourLogo>
        </DarkView>
    );
};

const DescGrid = ({header, items, ...props}) => {
    const _ = useI18n();

    return <>
        <Typography variant="h2">{header}</Typography>
        <Grid
            container
            columns={{xs: 1, md: 2, lg: 3}}
            mb={11.75}
            spacing={10}
            {...props}
        >
            {items.map(name =>
                <Grid key={name} item xs={1}>
                    <Typography variant="h5" sx={{
                        borderBottomColor: 'primary.main',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        mb: 1.25,
                        pb: 1.875,
                    }}>
                        {_(name)}
                    </Typography>
                    <Typography component="p" lineHeight={1.625}>
                        {_(name + ' description')}
                    </Typography>
                </Grid>
            )}
        </Grid>
    </>;
};
