import PropTypes from 'prop-types';

import {Link, List, ListItem, ListItemText} from '@mui/material';

import {parseHtml} from '.';


/** @see {List} */
export const DenseList = ({
    children, /** @ignore */dense, disablePadding = true, ...props
}) =>
    <List dense disablePadding={disablePadding} {...props}>
        {children && children.map
            ?
                children.map((child, i) =>
                    <ListItem key={i} disablePadding={disablePadding}>
                        {child}
                    </ListItem>,
                )
            :
                <ListItem disablePadding={disablePadding}>
                    {children}
                </ListItem>
        }
    </List>
;

/** @see {ListItemText} */
export const ListItemLink = ({
    children, href, primary, target, underline = 'hover', ...props
}) =>
    <ListItemText {...props}>
        <Link href={href} target={target} underline={underline}>
            {primary || children}
        </Link>
    </ListItemText>
;

ListItemLink.propTypes = {
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    underline: PropTypes.oneOf(['always', 'hover', 'none']),
};

/** @see {ListItemText} */
export const ListItemMarkText = ({mark, primary, ...props}) => {
    if (mark && primary) {
        const _ = s =>
            (s + '').replaceAll('<', '&lt;').replaceAll('\\', '\\\\')
        ;
        mark = new RegExp(`(${_(mark)})`, 'gi');
        primary = parseHtml(_(primary).replace(mark, '<mark>$1</mark>'));
    }

    return <ListItemText primary={primary} {...props} />;
};

ListItemMarkText.propTypes = {
    mark: PropTypes.string,
    primary: PropTypes.string,
};
