import PropTypes from 'prop-types';

import {TextField} from '@mui/material';


/** @see {TextField} */
export const InputField = ({onChange, onError, ...props}) => {
    const validate = ({target: {value, validity, willValidate}}) => {
        if (onError && willValidate) {
            let error;
            for (const e in validity) {
                if (validity[e]) {
                    error = e;
                    break;
                }
            }
            onError(error, value);
        }
        onChange && onChange(value);
    };

    return <TextField onChange={validate} {...props} />;
};

InputField.propTypes = {
    onError: PropTypes.func,
};

/** @see {InputField} */
export const EmailField = ({/** @ignore */type, ...props}) =>
    <InputField
        autoFocus
        autoComplete="email"
        placeholder="E-mail"
        type="email"
        {...props}
    />
;

/** @see {InputField} */
export const PasswordField = ({/** @ignore */type, ...props}) =>
    <InputField autoComplete="password" type="password" {...props} />
;
