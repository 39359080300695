import {Box, styled} from '@mui/material';

import {sx} from '../themes';


export * from './Breadcrumbs';
export * from './Diagrams';
export * from './Footer';
export * from './Header';
export * from './Layout';
export * from './Nav';
export * from './NetworkGraph';
export * from './SearchInput';
export * from './SignForm';
export * from './Typography';
export * from './View';
export * from './Whois';

/**
 * @link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 * @see {window.addEventListener}
 * @return {Function}
 */
export const addListener = (type, ...args) => {
    const types = type.split(/\s+/);

    for (type of types) {
        window.addEventListener(type, ...args);
    }

    return () =>
        types.forEach(type => window.removeEventListener(type, ...args))
    ;
};

/**
 * @see {addListener}
 * @param {!Function} onResize
 * @return {Function}
 */
export const addResizeListener = (onResize) => {
    onResize();

    return addListener('resize', onResize);
};

/**
 * @param {string} filename
 * @return {string}
 */
export const imgUrl = filename => `${process.env.PUBLIC_URL}/img/${filename}`;

/**
 * @param {string} email
 * @return {string}
 */
export const mailUrl = email => 'mailto:' + email;

/**
 * @param {number} n
 * @return {number[]}
 */
export const range = n => [...Array(n)].map((_, i) => i + 1);

/**
 * @param {string} phone
 * @return {string}
 */
export const telUrl = phone => 'tel:' + phone.replace(/\s+/g, '');

export const ContourLogo = styled(Box)(sx({
    backgroundImage: `url(${imgUrl('contour.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: {sm: 83},
    backgroundSize: {xs: '80% auto', sm: '498px 400px'},
    minHeight: 302,
    pt: 12.25,
    px: {lg: 6.719, xl: 13.438, xxl: 26.875},
    textAlign: {xs: 'center', lg: 'left'},
}));
