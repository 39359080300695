import {
    Box,
    Grid,
    IconButton,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material';

import useI18n from '../../i18n';
import {DenseList, Image, ListItemLink} from '../ui';
import {
    Breadcrumbs,
    ContourLogo,
    DarkView,
    imgUrl,
    mailUrl,
    range,
    telUrl,
} from '..';
import contacts from '../../contacts.json';


export const About = () => {
    const _ = useI18n();

    return (
        <DarkView>
            <Breadcrumbs>
                {_('About')}
            </Breadcrumbs>
            <ContourLogo itemScope itemType="https://schema.org/Organization">
                <Typography variant="h1">
                    <Typography component="span" noWrap>
                        {_('About laboratory')}
                    </Typography>
                    <Typography
                        variant="subtitle"
                        color="primary"
                        display={{xs: 'block', lg: 'inline'}}
                    >
                        ANDMAX
                    </Typography>
                </Typography>
                <Typography variant="subtitle1" width={{lg: 295}}>
                    <span itemProp="name">{_('Laboratory ANDMAX')}</span>
                    <br />{_('was founded in Moscow in 2022')}
                </Typography>
                <Typography variant="h3" mb={7.5}>
                    {_('Company mission')}:
                </Typography>
                <Grid
                    container
                    columns={{xs: 1, md: 3}}
                    fontSize={{xs: '1.125rem', md: '1.063rem'}}
                    lineHeight={{xs: 1.667, md: 1.765}}
                    spacing={3.125}
                    textAlign="left"
                >
                    {range(3).map(n => <Mission key={n} n={n} />)}
                </Grid>
                <Typography
                    variant="paragraph"
                    component="div"
                    fontSize="1rem"
                    textAlign="justify"
                    my={12}
                >
                    {_('Technology stack')}
                </Typography>
                <Typography variant="h3">{_('Requisites')}</Typography>
                <table>
                    <tbody>
                        <tr>
                            <td>{_('Full name')}</td>
                            <td itemProp="legalName">{contacts.name}</td>
                        </tr>
                        <tr>
                            <td>{_('Abbreviated name')}</td>
                            <td itemProp="alternateName">{contacts.abbr}</td>
                        </tr>
                        <tr>
                            <td>{_('Legal/postal address')}</td>
                            <td itemProp="address">{contacts.address}</td>
                        </tr>
                        <tr>
                            <td>{_('Fax')}</td>
                            <td itemProp="fax">{contacts.phone}</td>
                        </tr>
                        <tr>
                            <td>{_('Taxpayer identification number (INN)')}</td>
                            <td itemProp="identifier">{contacts.inn}</td>
                        </tr>
                        <tr>
                            <td>{_('Tax registration reason code (KPP)')}</td>
                            <td>{contacts.kpp}</td>
                        </tr>
                        <tr>
                            <td>{_('Primary state registration number (OGRN)')}</td>
                            <td>{contacts.ogrn}</td>
                        </tr>
                    </tbody>
                </table>
                <a href="#contacts" name="contacts"><span /></a>
                <DenseList variant="contacts" sx={{mt: 7.5}}>
                    <ListItemLink
                        href={mailUrl(contacts.email)}
                        itemProp="email"
                        target="_blank"
                    >
                        {contacts.email}
                    </ListItemLink>
                    <ListItemLink
                        href={telUrl(contacts.phone)}
                        itemProp="telephone"
                    >
                        {contacts.phone}
                    </ListItemLink>
                    <ListItemText>
                        <Tooltip title={_('Telegram support bot')}>
                            <IconButton onClick={telegramOpen}>
                                <Image src={imgUrl('telegram.svg')} />
                            </IconButton>
                        </Tooltip>
                    </ListItemText>
                </DenseList>
            </ContourLogo>
        </DarkView>
    );
};

const telegramOpen = () => window.open(contacts.telegram);

const Mission = ({n, ...props}) => {
    const _ = useI18n();

    return (
        <Grid item xs={1} {...props}>
            <Box backgroundColor="#cb624126" height={200} px={2} pt={6}>
                <Box
                    backgroundColor="primary.main"
                    height={5}
                    mb={4.75}
                    width={75}
                />
                {_('Mission #' + n)}
            </Box>
        </Grid>
    );
};
