import PropTypes from 'prop-types';

import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';

import useI18n, {I18nDate} from '../i18n';
import {sx} from '../themes';
import {DenseList, Image, ListItemMarkText} from './ui';
import {ErrorMessage, imgUrl} from '.';


/** @see {Grid} */
export const WhoisList = ({
    autnum = [], error, handler, inetnum = [], query, ...props
}) => <>
    <Grid container spacing={3.5} {...props}>
        {error
            ?
                <WhoisContainer
                    caption={<ErrorMessage>{error}</ErrorMessage>}
                />
            : <>
                {!autnum.length ? null :
                    <WhoisContainer
                        caption="Autnum"
                        handler={handler}
                        items={autnum}
                        name_key="asname"
                        query={query}
                        tooltip="Display analytic requests results"
                    />
                }
                {!inetnum.length ? null :
                    <WhoisContainer
                        caption="Inetnum"
                        handler={handler}
                        items={inetnum}
                        name_key="netname"
                        query={query}
                        tooltip="Display route tree"
                    />
                }
            </>
        }
    </Grid>
    {autnum.length || inetnum.length ? <StyledDivider /> : null}
</>;

WhoisList.propTypes = {
    autnum: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    handler: PropTypes.func,
    inetnum: PropTypes.arrayOf(PropTypes.object),
    query: PropTypes.string,
};

const Country = ({code}) =>
    <List disablePadding>
        <ListItem disablePadding>
            <ListItemIcon>
                <Image
                    src={imgUrl(`countries/${code.toLowerCase()}.svg`)}
                    height={40}
                    width={40}
                />
            </ListItemIcon>
            <ListItemText primary={code} />
        </ListItem>
    </List>
;

const WhoisContainer = ({caption, items = [], sx, ...props}) =>
    <Grid
        item
        container
        direction="column"
        mb={{xs: 6.75, xl: 0}}
        rowSpacing={6.75}
        xxl
    >
        <Grid item>
            <Typography
                variant="caption"
                bgcolor="background.paper"
                component="div"
            >
                {caption}
            </Typography>
        </Grid>
        {items.map((item, i) =>
            <WhoisItem
                key={i}
                num_key={caption.toLowerCase()}
                {...item}
                {...props}
            />
        )}
    </Grid>
;

const WhoisItem = ({
    country,
    description,
    handler,
    last_modified,
    name_key,
    num_key,
    query,
    tooltip,
    ...props
}) => {
    const _ = useI18n();

    return (
        <Grid item container variant="whois" columns={8} spacing={3.125}>
            <Grid item>
                <Country code={country || 'un'} />
            </Grid>
            <Grid item xs={2}>
                <DenseList>
                    <ListItemText sx={{mb: 1}}>
                        {!handler ? props[num_key] :
                            <Tooltip title={_(tooltip)}>
                                <StyledTypography
                                    component="span"
                                    onClick={() =>
                                        handler(num_key, props[num_key])
                                    }
                                >
                                    {props[num_key]}
                                </StyledTypography>
                            </Tooltip>
                        }
                    </ListItemText>
                    <ListItemMarkText mark={query} primary={props[name_key]} />
                </DenseList>
            </Grid>
            <Grid item xs>
                <DenseList>
                    <ListItemMarkText mark={query} primary={description} />
                    {!last_modified ? null :
                        <ListItemText sx={description && {mt: 1}}>
                            {_('Last modified')}<br />
                            <code><I18nDate date={last_modified} /></code>
                        </ListItemText>
                    }
                </DenseList>
            </Grid>
        </Grid>
    );
};

const StyledDivider = styled(Divider)(({
    theme: {breakpoints: b, unstable_sx: sx},
}) => sx({
    my: 13.625,
    [b.down('xl')]: {
        visibility: 'hidden',
    },
}));

const StyledTypography = styled(Typography)(sx({
    border: '1px dotted transparent',
    color: 'primary.main',
    cursor: 'pointer',
    '&:hover': {
        borderBottomColor: 'inherit',
    },
}));
