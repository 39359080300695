import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import useI18n from '../../i18n';
import fetch from '../../fetch';
import {useApp} from '../../App';
import {EmailField, PasswordField, RouteLink} from '../ui';
import {DarkView, SignForm, SubmitButton} from '..';


export const SignUp = () => {
    const _ = useI18n();
    const {search} = useLocation();
    const {setPending} = useApp();
    const [user, setUser] = useState({email: '', password: ''});
    const [rePassword, setRePassword] = useState('');
    const [messages, setMessages] = useState({
        email: {}, password: {}, rePassword: {},
    });
    const [errors, setErrors] = useState({
        email: '', password: '', rePassword: '',
    });

    useEffect(() => setMessages({
        email: {
            typeMismatch:  _('E-mail is incorrect'),
            valueMissing: _('E-mail is required'),
        },
        password: {
            valueMissing: _('Password is required'),
            valuesNotMatch: _("Passwords aren't match"),
        },
        rePassword: {
            valueMissing: _('Retype password is required'),
        },
    }), [_]);

    const disabled = Object.values(errors).some(e => e !== 'valid');

    const passwordErrorHandler = (e, v) => {
        e === 'valid' && errors.rePassword === 'valid'
            && v !== rePassword && (e = 'valuesNotMatch')
        ;
        setErrors({...errors, password: e});
    };

    const rePasswordErrorHandler = (e, v) => {
        const newErrors = {...errors, rePassword: e};
        e === 'valid' && (
            (
                errors.password === 'valid'
                    && user.password !== v
                    && (newErrors.password = 'valuesNotMatch')
            ) || (
                errors.password === 'valuesNotMatch'
                    && user.password === v
                    && (newErrors.password = 'valid')
            )
        );
        setErrors(newErrors);
    };

    const submit = disabled ? null : async token => {
        setPending(true);
        const response = await fetch(
            '/backend/signup', 'POST', {...user, token},
        );
        const {error} = await response.json();
        setMessages({...messages, email: {
            ...messages.email, valid: error ? _(error) : 'verified',
        }});
        setPending(false);
    };

    return (
        <DarkView center>
            {messages.email.valid === 'verified'
                ? <SignForm header={_('Continue registration')} />
                : <SignForm
                    header={_('Registration')}
                    links={
                        <RouteLink to={'/signin' + search} underline="always">
                            {_('Have an account')}?
                        </RouteLink>
                    }
                >
                    <EmailField
                        error={!!messages.email[errors.email]}
                        helperText={messages.email[errors.email]}
                        onChange={email => setUser({...user, email})}
                        onError={email => setErrors({...errors, email})}
                        required
                        value={user.email}
                    />
                    <PasswordField
                        error={!!messages.password[errors.password]}
                        helperText={messages.password[errors.password]}
                        onChange={password => setUser({...user, password})}
                        onError={passwordErrorHandler}
                        placeholder={_('Password')}
                        required
                        value={user.password}
                    />
                    <PasswordField
                        autoComplete="re-password"
                        error={!!messages.rePassword[errors.rePassword]}
                        helperText={messages.rePassword[errors.rePassword]}
                        onChange={setRePassword}
                        onError={rePasswordErrorHandler}
                        placeholder={_('Retype password')}
                        required
                        value={rePassword}
                    />
                    <SubmitButton
                        action="signup"
                        disabled={disabled}
                        submit={submit}
                    >
                        {_('Sign up')}
                    </SubmitButton>
                </SignForm>
            }
        </DarkView>
    );
};
