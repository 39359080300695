import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Grid, Typography} from '@mui/material';

import useI18n from '../../i18n';
import {Image} from '../ui';
import {ContourLogo, DarkView, imgUrl, NavGrid, range, SearchInput} from '..';


export const Home = () => {
    const _ = useI18n();
    const navigate = useNavigate();
    const [query, setQuery] = useState('');

    return (
        <DarkView>
            <NavGrid />
            <ContourLogo>
                <Typography variant="h1">
                    {_('Laboratory')}
                    <Typography
                        variant="subtitle"
                        color="primary"
                        display={{xs: 'block', lg: 'inline'}}
                    >
                        ANDMAX
                    </Typography>
                </Typography>
                <Typography
                    variant="h4"
                    fontWeight={300}
                    lineHeight={{xs: 1.5, md: false}}
                >
                    {_('Internet data analysis and network modeling')}
                </Typography>
                <SearchInput
                    onChange={setQuery}
                    onSearch={!query ? null :
                        () => navigate('/analysis?query=' + query)
                    }
                    value={query}
                />
            </ContourLogo>
            <Grid container columns={{xs: 1, md: 2, lg: 3}} mb={15.625} mt={16}>
                {range(6).map(n =>
                    <Grid key={n} item variant="bullet" xs={1}>
                        <Image src={imgUrl(`bullets/${n}.svg`)} />
                        <Typography variant="h5">
                            {_('Bullet title #' + n)}
                        </Typography>
                        <p>{_('Bullet #' + n)}</p>
                    </Grid>
                )}
            </Grid>
        </DarkView>
    );
};
