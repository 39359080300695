import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

import {Button, Grid, Typography} from '@mui/material';

import useI18n from '../i18n';
import {DenseList, ListItemLink} from './ui';
import {telUrl} from '.';
import contacts from '../contacts.json';


/** @see {Grid} */
export const SignForm = ({children, header, links, ...props}) => {
    const get_locale = useI18n();

    return (
        <GoogleReCaptchaProvider
            language={get_locale()}
            reCaptchaKey="6Lc2XOogAAAAAGzF7lzZCxVAWtQ5CZTaSl7Y0RxV"
        >
            <form onSubmit={e => e.preventDefault()}>
                <Grid
                    container
                    variant="form"
                    direction="column"
                    spacing={2.5}
                    {...props}
                >
                    <Grid item>
                        <Typography variant="h3" mt={4.75}>
                            {header}
                        </Typography >
                    </Grid>
                    {children && children.map
                        ?
                            children.map((child, i) =>
                                <Grid item key={i}>{child}</Grid>
                            )
                        :
                            <Grid item>{children}</Grid>
                    }
                    {links && <Grid item mt={-.875}>{links}</Grid>}
                    <Grid item mt={7.875}>
                        <DenseList variant="contacts">
                            <ListItemLink href={telUrl(contacts.phone)}>
                                <Typography color="common.white">
                                    {contacts.phone}
                                </Typography>
                            </ListItemLink>
                        </DenseList>
                    </Grid>
                </Grid>
            </form>
        </GoogleReCaptchaProvider>
    );
};

SignForm.propTypes = {
    header: PropTypes.string,
    links: PropTypes.node,
};

/** @see {Button} */
export const SubmitButton = ({action, disabled, style, submit, ...props}) => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    const cursor = !executeRecaptcha || disabled ? 'not-allowed' : 'inherit';
    const clickHandler = submit && !disabled
        ? async () => await submit(await executeRecaptcha(action))
        : null
    ;

    return (
        <Button
            onClick={clickHandler}
            style={{...style, cursor}}
            type="submit"
            {...props}
        />
    );
};

SubmitButton.propTypes = {
    action: PropTypes.string.isRequired,
    submit: PropTypes.func,
};
