import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

import {Grid, ListItemText} from '@mui/material';

import useI18n, {I18nWrapper} from '../i18n';
import {DenseList} from './ui';


/** @see {Grid} */
export const Diagrams = ({info, sankey, title, ...props}) => {
    const _ = useI18n();

    let data;
    const prefixCount = info['Prefix count'];
    if (prefixCount != null) {
        const matchedCount = info['Matched count'] || 0;
        data = {
            [_('Coverage')]: {
                color: 'black', value: matchedCount,
            },
            [_('Not covered')]: {
                color: 'silver', value: prefixCount - matchedCount,
            },
        };
    }

    return (
        <Grid container direction="column" sx={{height: 1}} {...props}>
            <Grid item xs>
                <Sankey data={sankey} title={title} />
            </Grid>
            <Grid item container>
                <Grid item xs>
                    <DenseList disablePadding={false} sx={{m: 10}}>
                        {Object.keys(info).sort().map((k, i) =>
                            <ListItemText
                                key={i}
                                primary={`${_(k)}: ${info[k]}`}
                            />
                        )}
                    </DenseList>
                </Grid>
                {!data ? null :
                    <Grid item sx={{px: 10}} xs>
                        <Pie
                            data={data}
                            title={_('Operator network coverage')}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

Diagrams.propTypes = {
    info: PropTypes.objectOf(PropTypes.number).isRequired,
    sankey: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
};

const PlotWrapper = ({data, title, ...props}) =>
    <I18nWrapper
        attr="data-title"
        Component={Plot}
        data={[data]}
        layout={{title}}
        wstyle={{height: '100%'}}
        {...props}
    />
;

const Pie = ({data, ...props}) => {
    const colors = [];
    const values = [];

    for (const item of Object.values(data)) {
        colors.push(item.color);
        values.push(item.value);
    }

    return (
        <PlotWrapper
            data={{
                marker: {colors},
                labels: Object.keys(data),
                type: 'pie',
                values,
            }}
            {...props}
        />
    );
};

const Sankey = ({data, nodeColor: color = 'black', ...props}) => {
    const idx = {};
    const label = [];
    const source = [];
    const target = [];
    const value = [];

    const getIdx = node => {
        if (!((node = 'AS' + node) in idx)) {
            idx[node] = label.length;
            label.push(node);
        }
        return idx[node];
    };

    for (const item of data) {
        source.push(getIdx(item.source));
        target.push(getIdx(item.target));
        value.push(item.weight);
    }

    return (
        <PlotWrapper
            data={{
                link: {source, target, value},
                node: {color, label},
                type: 'sankey',
            }}
            {...props}
        />
    );
};
