import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PropTypes from 'prop-types';

import useI18n from './i18n';
import fetch from './fetch';
import {LayoutContainer} from './components';
import {
    About,
    Analysis,
    Home,
    Products,
    Publication,
    Publications,
    SignIn,
    SignUp,
    Status,
} from './components/views';


const AppContext = createContext({});

export const useApp = () => useContext(AppContext);

const App = ({description}) => {
    const _ = useI18n();
    const navigation = useMemo(
        () => Object.entries(routes).map(([label, to]) => [_(label), to]),
        [_],
    );

    const [pending, setPending] = useState(false);
    const [signed, setSigned] = useState(!!auth.getUser());

    const signIn = (email, info) => {
        localStorage.setItem('email', email);
        auth.store(info);
        setSigned(true);
    };

    const signOut = useCallback(() => {
        auth.clear();
        setSigned(false);
    }, []);

    const request = useCallback(async (url, method, body, headers) => {
        const response = await fetch(
            url, method, body, {...auth.getHeaders(), ...headers},
        );
        response.status === 401 && signOut();
        return response;
    }, [signOut]);

    useEffect(() => {
        signed && auth.refresh(auth.getUser()).then(signOut);
    }, [signed, signOut]);

    return (
        <AppContext.Provider
            value={{description, navigation, request, setPending, signed}}
        >
            <BrowserRouter>
                <LayoutContainer
                    onSignOut={signed ? signOut : null}
                    pending={pending}
                >
                    <Routes>
                        <Route element={<Home />} exact path="/" />
                        <Route element={<Analysis />} path={routes.Analysis} />
                        <Route
                            element={<Products />}
                            path={routes['Products & services']}
                        />
                        <Route
                            element={<Publications />}
                            exact
                            path={routes.Publications}
                        />
                        <Route
                            element={<Publication />}
                            path={routes.Publications + '/:id'}
                        />
                        <Route element={<About />} path={routes.About} />
                        <Route
                            element={<SignIn onSignIn={signIn} />}
                            path="/signin"
                        />
                        <Route element={<SignUp />} path="/signup" />
                        <Route
                            element={
                                <Status status={404} statusText="Not Found" />
                            }
                            path="*"
                        />
                    </Routes>
                </LayoutContainer>
            </BrowserRouter>
        </AppContext.Provider>
    );
};

App.propTypes = {
    description: PropTypes.string.isRequired,
};

const auth = {
    clear: () => [
        'Authorization', 'email', 'refresh_token',
    ].forEach(k => localStorage.removeItem(k)),

    getHeaders: () => ({
        Authorization: localStorage.getItem('Authorization'),
    }),

    getUser: () => {
        const email = localStorage.getItem('email');
        const token = localStorage.getItem('refresh_token');
        if (email && token) {
            return {email, token};
        }
    },

    refresh: user => fetch('/backend/refresh', 'POST', user)
        .then(response => new Promise(async (resolve, reject) => {
            response.ok || resolve();
            auth.store(response = await response.json());
            user.token = response.refresh_token;
            setTimeout(() => reject(user), response.expires_in * 1e3);
        }))
        .catch(auth.refresh),

    store: ({access_token, refresh_token, token_type}) =>
        Object.entries({
          Authorization: `${token_type} ${access_token}`, refresh_token,
        }).forEach(args => localStorage.setItem(...args)),
};

const routes = {
    Analysis: '/analysis',
    'Products & services': '/products',
    Publications: '/publications',
    About: '/about',
};

export default App;
