import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Grid, Typography} from '@mui/material';

import {imgUrl} from '..';
import {Image} from './Image';


/** @see {Grid} */
export const Logo = ({children, size = 60, style, ...props}) => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            variant="logo"
            columns={2}
            onClick={() => navigate('/')}
            {...props}
        >
            <Grid item>
                <Image height={size} src={imgUrl('logo.svg')} />
            </Grid>
            <Grid item xs>
                <Typography fontSize={size / 3} fontWeight="bold" ml={1}>
                    ANDMAXLAB
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {children}
            </Grid>
        </Grid>
    );
};

Logo.propTypes = {
    size: PropTypes.number,
};
