import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Button, Grid, styled, Typography} from '@mui/material';

import useI18n from '../i18n';
import {sx} from '../themes';
import {useApp} from '../App';
import {Image, Logo, RouteLink} from './ui';
import {imgUrl} from '.';
import contacts from '../contacts.json';


/** @see {Grid} */
export const Header = ({onSignOut, ...props}) => {
    const _ = useI18n();
    const navigate = useNavigate();
    const {pathname, search} = useLocation();
    const {description} = useApp();

    const redirect = url =>
        pathname === '/' ? url : `${url}?redirect=${pathname}${search}`
    ;

    return (
        <Grid
            container
            component="header"
            alignItems={{xs: 'center', md: 'flex-start', lg: 'center'}}
            columns={{xs: 2, lg: 3}}
            columnSpacing={{md: 18.125}}
            {...props}
        >
            <StyledLogo item xs={2} md>
                {description}
            </StyledLogo>
            {['/signin', '/signup'].includes(pathname) ? null : <>
                <Grid item>
                    <Typography fontWeight={600} noWrap>
                        {contacts.phone}
                    </Typography>
                </Grid>
                <Grid
                    item
                    fontWeight={400}
                    textAlign="right"
                    xs md={2} lg="auto"
                >
                    {onSignOut
                        ?
                            <AccountButton onClick={onSignOut}>
                                {_('Sign out')}
                            </AccountButton>
                        :
                            <>
                                <AccountButton onClick={() =>
                                    navigate(redirect('/signin'))
                                }>
                                    {_('Sign in')}
                                </AccountButton>
                                <Typography color="secondary">
                                    <RouteLink
                                        to={redirect('/signup')}
                                        underline="always"
                                    >
                                        {_('Sign up')}
                                    </RouteLink>
                                </Typography>
                            </>
                    }
                </Grid>
            </>}
        </Grid>
    );
};

Header.propTypes = {
    onSignOut: PropTypes.func,
};

const AccountButton = props =>
    <Button
        startIcon={<Image src={imgUrl('account.svg')} />}
        {...props}
    />
;

const StyledLogo = styled(Logo)(sx({
    pb: {xs: 2, md: 0},
    textAlign: {xs: 'center', md: 'left'},
    whiteSpace: 'nowrap',
}));
