import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import {I18nProvider} from './i18n';
import App from './App';

import '@fontsource/montserrat';
import './index.css';


const description = document.querySelector('meta[name="description"]').content;

createRoot(document.getElementById('root')).render(
    <StrictMode>
        <I18nProvider locale="ru">
            <App description={description} />
        </I18nProvider>
    </StrictMode>
);
