import {forwardRef} from 'react';
import PropTypes from 'prop-types';

import {
    Backdrop,
    CircularProgress,
    Container,
    Grid,
    ThemeProvider,
} from '@mui/material';

import {dark} from '../themes';
import {Footer, Header, NavBar} from '.';


/** @see {Grid} */
export const LayoutContainer = ({children, onSignOut, pending, ...props}) =>
    <ThemeProvider theme={dark}>
        <Grid container direction="column" minHeight="100vh" {...props}>
            <Grid item>
                <NavBar />
            </Grid>
            <LayoutItem>
                <Header onSignOut={onSignOut} />
            </LayoutItem>
            {children}
            <LayoutItem sx={{backgroundColor: 'background.paper'}}>
                <Footer />
            </LayoutItem>
        </Grid>
        <Backdrop
            open={pending}
            sx={{color: 'secondary.main', cursor: 'progress'}}
        >
            <CircularProgress />
        </Backdrop>
    </ThemeProvider>
;

LayoutContainer.propTypes = {
    onSignOut: PropTypes.func,
    pending: PropTypes.bool.isRequired,
};

/** @see {Grid} */
export const LayoutItem = forwardRef(({children, ...props}, ref) =>
    <Grid item variant="layout" {...props}>
        <Container ref={ref}>
            {children}
        </Container>
    </Grid>
);
