import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Backdrop, Box, Typography} from '@mui/material';

import fetch from '../../fetch';
import useI18n from '../../i18n';
import {useApp} from '../../App';
import {Image, parseHtml} from '../ui';
import {addListener, LightView, NavGrid, TypographyDate} from '..';
import {articleDates} from '.';


export const Publication = () => {
    const _ = useI18n();
    const {id} = useParams();
    const {setPending} = useApp();
    const [content, setContent] = useState();
    const [viewImg, setViewImg] = useState();

    useEffect(() => {
        setPending(true);
        fetch(`${process.env.PUBLIC_URL}/articles/${id}.html`)
            .then(async response => {
                setContent(parseHtml(await response.text()));
                setPending(false);
            })
        ;
        return addListener(
            'keydown', e => e.key === 'Escape' && setViewImg(null),
        );
    }, [id, setPending]);

    const clickHandler = e =>
        e.target instanceof HTMLImageElement && setViewImg(e.target)
    ;

    return (
        <LightView bgcolor="background.paper">
            <NavGrid />
            <Box itemScope itemType="https://schema.org/Article" sx={{
                backgroundColor: 'common.white',
                borderRadius: 1 / 3,
                mb: 4.125,
                mx: {xl: 26.75},
                p: 3.125,
            }}>
                <TypographyDate
                    itemProp="datePublished"
                    date={articleDates[id - 1]}
                    dateTime={articleDates[id - 1] + ':00+00:00'}
                />
                <Typography variant="h2" itemProp="headline">
                    {_('Article title #' + id)}
                </Typography>
                <Box
                    itemProp="articleBody"
                    component="article"
                    onClick={clickHandler}
                    sx={{mb: 6, '& img': {cursor: 'pointer'}}}
                >
                    {content}
                </Box>
            </Box>
            <Backdrop open={!!viewImg} onClick={() => setViewImg(null)}>
                {viewImg && <Image alt={viewImg.alt} src={viewImg.src} />}
            </Backdrop>
        </LightView>
    );
};
