import PropTypes from 'prop-types';

import {IconButton, InputAdornment, OutlinedInput} from '@mui/material';
import {Search} from '@mui/icons-material';

import useI18n from '../i18n';


/** @see {OutlinedInput} */
export const SearchInput = ({onChange, onSearch, ...props}) => {
    const _ = useI18n();

    return (
        <OutlinedInput
            autoFocus
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        color="primary"
                        disabled={!props.value}
                        onClick={onSearch}
                    >
                        <Search />
                    </IconButton>
                </InputAdornment>
            }
            fullWidth
            onChange={onChange && (e => onChange(e.target.value))}
            placeholder={_('IP address, ASN or text')}
            ref={el => {el && (el.onsearch = onSearch)}}
            type="search"
            {...props}
        />
    );
};

SearchInput.propTypes = {
    onSearch: PropTypes.func,
};
