/**
 * @link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 * @param {string} url
 * @param {string=} method
 * @param {Object=} body
 * @param {Object=} headers
 * @return {!Promise<!Response>}
 */
const fetch = async (url, method = 'GET', body, headers) => {
    headers = new Headers(headers);
    headers.set('X-Requested-With', 'XMLHttpRequest');
    method = method.toUpperCase();

    if (body) {
        if (['GET', 'HEAD', 'OPTIONS'].includes(method)) {
            url = [url, new URLSearchParams(body)].join('?');
            body = null;
        } else if (!(body instanceof FormData)) {
            body = JSON.stringify(body);
            headers.set('Content-Type', 'application/json; charset=UTF-8');
        }
    }

    const response = await window.fetch(url, {body, headers, method});

    if (!response.ok) {
        let json;
        try {
            json = await response.json();
            const {
                detail, error, error_description, errorMessage, ...content
            } = json;
            (e => e && (content.error = e))(
                errorMessage || error_description || error || detail,
            );
            json = content;
        } catch {
            const {status, statusText} = response;
            json = {error: `${status} ${statusText}`};
        }
        response.json = async () => json;
    }

    return response;
};

export default fetch;
