import {useState} from 'react';
import {useLocation} from 'react-router-dom';

import {
    AppBar,
    Box,
    Grid,
    MenuItem,
    MenuList,
    styled,
    Typography,
} from '@mui/material';
import {Close, Menu} from '@mui/icons-material';

import {sx} from '../themes';
import {useApp} from '../App';
import {RouteLink} from './ui';


/** @see {Box} */
export const NavBar = props => {
    const {navigation} = useApp();
    const {pathname} = useLocation();
    const [open, setOpen] = useState(false);
    const Icon = open ? Close : Menu;

    return (
        <StyledBox component="nav" {...props}>
            <AppBar component="div" position="static">
                <Icon fontSize="large" onClick={() => setOpen(!open)} />
            </AppBar>
            {!open ? null :
                <MenuList dense>
                    {navigation.map(([label, to]) => to === pathname
                        ?
                            <Typography key={to} color="primary">
                                {label}
                            </Typography>
                        :
                            <MenuItem key={to}>
                                <RouteLink to={to}>{label}</RouteLink>
                            </MenuItem>
                    )}
                </MenuList>
            }
        </StyledBox>
    );
};

/** @see {Grid} */
export const NavGrid = props =>
    <Grid
        container
        component="nav"
        justifyContent={{xs: 'space-between', lg: 'flex-end'}}
        textTransform="uppercase"
        {...props}
    >
        <NavList ml={{lg: 7.625}} />
    </Grid>
;

/** @see {Grid} */
export const NavList = props => {
    const {navigation} = useApp();
    const {pathname} = useLocation();

    return <>
        {navigation.map(([label, to]) => to === pathname ? null :
            <Grid key={to} item {...props}>
                <RouteLink to={to}>{label}</RouteLink>
            </Grid>
        )}
    </>
};

const StyledBox = styled(Box)(sx({
    display: {sm: 'none'},
    '& svg': {
        cursor: 'pointer',
    },
    '& [role="menu"]': {
        alignItems: 'center',
        backgroundColor: 'common.white',
        color: 'common.black',
        display: 'flex',
        flexDirection: 'column',
        font: 'inherit',
        my: 1,
        textTransform: 'uppercase',
    },
}));
