import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Grid} from '@mui/material';

import useI18n from '../../i18n';
import fetch from '../../fetch';
import {useApp} from '../../App';
import {EmailField, PasswordField, RouteLink} from '../ui';
import {DarkView, SignForm, SubmitButton} from '..';


export const SignIn = ({onSignIn}) => {
    const _ = useI18n();
    const navigate = useNavigate();
    const {search} = useLocation();
    const {setPending} = useApp();
    const [params] = useSearchParams();
    const [user, setUser] = useState({email: '', password: ''});
    const [messages, setMessages] = useState({email: {}, password: {}});
    const [errors, setErrors] = useState({email: '', password: ''});

    useEffect(() => setMessages({
        email: {
            typeMismatch:  _('E-mail is incorrect'),
            valueMissing: _('E-mail is required'),
        },
        password: {
            valueMissing: _('Password is required'),
        },
    }), [_]);

    const disabled = Object.values(errors).some(e => e !== 'valid');

    const submit = disabled ? null : async token => {
        setPending(true);
        const response = await fetch(
            '/backend/signin', 'POST', {...user, token},
        );
        const {error, ...userinfo} = await response.json();
        if (error) {
            setMessages({...messages, email: {
                ...messages.email, valid: _(error),
            }});
        } else {
            onSignIn && onSignIn(user.email, userinfo);
            navigate(params.get('redirect') || '/');
        }
        setPending(false);
    };

    return (
        <DarkView center>
            <SignForm
                header={_('Login')}
                links={
                    <Grid container>
                        <Grid item xs>
                            <RouteLink
                                to={'/signup' + search}
                                underline="always"
                            >
                                {_('Sign up')}
                            </RouteLink>
                        </Grid>
                        <Grid item textAlign="right" xs>
                            <RouteLink to="/restore" underline="always">
                                {_('Restore password')}
                            </RouteLink>
                        </Grid>
                    </Grid>
                }
            >
                <EmailField
                    error={!!messages.email[errors.email]}
                    helperText={messages.email[errors.email]}
                    onChange={email => setUser({...user, email})}
                    onError={email => setErrors({...errors, email})}
                    required
                    value={user.email}
                />
                <PasswordField
                    error={!!messages.password[errors.password]}
                    helperText={messages.password[errors.password]}
                    onChange={password => setUser({...user, password})}
                    onError={password => setErrors({...errors, password})}
                    placeholder={_('Password')}
                    required
                    value={user.password}
                />
                <SubmitButton
                    action="signin"
                    disabled={disabled}
                    submit={submit}
                >
                    {_('Sign in')}
                </SubmitButton>
            </SignForm>
        </DarkView>
    );
};

SignIn.propTypes = {
    onSignIn: PropTypes.func,
};
