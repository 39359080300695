import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Graph from 'graphology';
import {random} from 'graphology-layout';
import forceAtlas2 from 'graphology-layout-forceatlas2';
import {
    ControlsContainer,
    FullScreenControl,
    SearchControl,
    SigmaContainer,
    useFullScreen,
    useRegisterEvents,
    useSigma,
    ZoomControl,
} from '@react-sigma/core';
import {
    LayoutForceAtlas2Control,
    useWorkerLayoutForceAtlas2,
} from '@react-sigma/layout-forceatlas2';
import '@react-sigma/core/lib/react-sigma.min.css';

import {I18nWrapper} from '../i18n';


/** @see {SigmaContainer} */
export const NetworkGraph = ({
    children,
    edges = {},
    fa2settings,
    iterations,
    nodes = {},
    nodeSize: size = 10,
    settings,
    style,
    ...props
}) => {
    const graph = useMemo(() => {
        const graph = new Graph();
        Object.entries(nodes).forEach(args => graph.addNode(...args));
        Object.entries(edges).forEach(([key, {source, target, ...attrs}]) =>
            graph.addEdgeWithKey(key, source, target, attrs),
        );
        setPositions(graph, random(graph));
        return graph;
    }, [edges, nodes]);

    useEffect(
        () => graph.updateEachNodeAttributes((n, attrs) => ({...attrs, size})),
        [graph, size],
    );

    useEffect(
        () => iterations && setPositions(graph, forceAtlas2(graph, {
            iterations,
            settings: fa2settings,
        })),
        [fa2settings, graph, iterations],
    );

    return (
        <SigmaContainer
            graph={graph}
            settings={{renderEdgeLabels: true, ...settings}}
            style={{backgroundColor: 'transparent', ...style}}
            {...props}
        >
            <GraphEvents />
            <GraphControls settings={{settings: fa2settings}} />
            {children}
        </SigmaContainer>
    );
};

NetworkGraph.propTypes = {
    edges: PropTypes.objectOf(PropTypes.object),
    fa2settings: PropTypes.object,
    iterations: PropTypes.number,
    nodes: PropTypes.objectOf(PropTypes.object),
    nodeSize: PropTypes.number,
};

const GraphControls = ({settings}) => {
    const {isFullScreen} = useFullScreen();
    const {isRunning} = useWorkerLayoutForceAtlas2(settings);

    return <>
        <ControlsContainer position="top-left">
            <I18nWrapper attr="placeholder" Component={SearchControl} />
        </ControlsContainer>
        <ControlsContainer position="bottom-left">
            <I18nWrapper Component={ZoomControl} />
            <I18nWrapper Component={FullScreenControl} dep={isFullScreen} />
        </ControlsContainer>
        <ControlsContainer position="bottom-right">
            <I18nWrapper
                Component={LayoutForceAtlas2Control}
                dep={isRunning}
                settings={settings}
            />
        </ControlsContainer>
    </>
};

const GraphEvents = () => {
    const registerEvents = useRegisterEvents();
    const sigma = useSigma();
    const [draggedNode, setDraggedNode] = useState();

    useEffect(() => {
        const disableAutoscaleAtFirstDown = () =>
            sigma.getCustomBBox() || sigma.setCustomBBox(sigma.getBBox())
        ;
        const move = e => {
            if (!draggedNode) {
                return;
            }
            sigma.getGraph().mergeNodeAttributes(
                draggedNode, sigma.viewportToGraph(e),
            );
            // Prevent sigma to move camera:
            e.preventSigmaDefault();
            e.original.preventDefault();
            e.original.stopPropagation();
        };
        const reset = () => {
            if (!draggedNode) {
                return;
            }
            setDraggedNode(null);
            sigma.getGraph().removeNodeAttribute(draggedNode, 'highlighted');
        };
        registerEvents({
            downNode: e => {
                setDraggedNode(e.node);
                sigma.getGraph().setNodeAttribute(e.node, 'highlighted', true);
            },
            mousedown: disableAutoscaleAtFirstDown,
            mousemove: move,
            mouseup: reset,
            touchdown: disableAutoscaleAtFirstDown,
            touchmove: move,
            touchup: reset,
        });
    }, [draggedNode, registerEvents, sigma]);
};

const setPositions = (graph, positions) =>
    Object.entries(positions).forEach(attrs =>
        graph.mergeNodeAttributes(...attrs),
    )
;
