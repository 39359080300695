import {forwardRef} from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography} from '@mui/material';

import useI18n from '../i18n';
import {RouteLink} from './ui';
import {NavList} from '.';


/** @see {Grid} */
export const Breadcrumbs = forwardRef(({
    children, separator = '→', ...props
}, ref) => {
    const _ = useI18n();

    return (
        <Grid
            container
            component="nav"
            justifyContent="flex-end"
            columns={4}
            {...props}
            ref={ref}
        >
            <Grid item mb={{xs: 3, lg: 0}} xs={4} lg>
                <Grid container textTransform="uppercase">
                    <NavList mr={7.25} />
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="subtitle2" color="primary">
                    <RouteLink to="/">{_('Home')}</RouteLink>
                </Typography>
            </Grid>
            <Grid item mx={1.375}>
                <Typography variant="subtitle2" color="secondary">
                    {separator}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle2" color="secondary">
                    {children}
                </Typography>
            </Grid>
        </Grid>
    );
});

Breadcrumbs.propTypes = {
    separator: PropTypes.node,
};
