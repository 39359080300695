import {useLocation} from 'react-router-dom';

import {Grid, ListItemText, styled, Typography} from '@mui/material';

import {useApp} from '../App';
import {DenseList, ListItemLink, Logo, RouteLink} from './ui';
import {mailUrl, telUrl} from '.';
import contacts from '../contacts.json';


/** @see {Grid} */
export const Footer = props => {
    const {pathname} = useLocation();
    const {description, navigation} = useApp();

    return (
        <Grid container component="footer" {...props}>
            <StyledLogo item size={40} md>
                © 2022 {description}
            </StyledLogo>
            <Grid item variant="footer">
                <DenseList>
                    {navigation.map(([label, to]) =>
                        <ListItemText key={to}>
                            {to === pathname
                                ?
                                    <Typography color="secondary">
                                        {label}
                                    </Typography>
                                :
                                    <RouteLink to={to}>{label}</RouteLink>
                            }
                        </ListItemText>
                    )}
                </DenseList>
            </Grid>
            <Grid item minWidth={30} xs md="auto" />
            <Grid item variant="footer" mr={{xl: 30}}>
                <DenseList>
                    <ListItemLink
                        href={mailUrl(contacts.email)}
                        target="_blank"
                    >
                        {contacts.email}
                    </ListItemLink>
                    <ListItemLink href={telUrl(contacts.phone)}>
                        {contacts.phone}
                    </ListItemLink>
                </DenseList>
            </Grid>
        </Grid>
    );
};

const StyledLogo = styled(Logo)(({
    theme: {breakpoints: b, unstable_sx: sx},
}) => sx({
    color: 'secondary.main',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [b.down('lg')]: {
        color: 'transparent',
    },
    [b.down('md')]: {
        display: 'none',
    },
}));
