import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import {styled} from '@mui/material';


/** @see {NavLink} */
export const RouteLink = ({noWrap = true, underline = 'hover', ...props}) => {
    const StyledNavLink = styled(NavLink)({
        color: 'inherit',
        textDecoration: underline === 'always' ? 'underline' : 'none',
        whiteSpace: noWrap ? 'nowrap' : 'normal',
        '&:hover': {
            textDecoration: underline === 'none' ? 'none' : 'underline',
        },
    });

    return <StyledNavLink {...props} />;
};

RouteLink.propTypes = {
    noWrap: PropTypes.bool,
    underline: PropTypes.oneOf(['always', 'hover', 'none']),
};
