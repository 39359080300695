import PropTypes from 'prop-types';

import {Divider, Typography} from '@mui/material';

import {LightView} from '..';


export const Status = ({status, statusText}) => {
    return (
        <LightView center>
            <Typography variant="h3" my={1}>
                {status < 400 ? 'Status' : 'Error'}
            </Typography>
            <Typography variant="h1" mb={2}>
                {status}
            </Typography>
            <Divider />
            <Typography variant="subtitle1" pt={4}>
                {statusText}
            </Typography>
        </LightView>
    );
};

Status.propTypes = {
    status: PropTypes.number.isRequired,
    statusText: PropTypes.string,
};
