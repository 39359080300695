import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button, Divider, Grid, Typography} from '@mui/material';

import useI18n from '../../i18n';
import {Image, RouteLink} from '../ui';
import {Breadcrumbs, imgUrl, LightView, TypographyDate} from '..';


export const Publications = () => {
    const _ = useI18n();
    const navigate = useNavigate();
    const dates = [...articleDates].reverse();
    const l = dates.length;

    return (
        <LightView bgcolor="background.paper">
            <Breadcrumbs>
                {_('Publications')}
            </Breadcrumbs>
            <Grid
                container
                columns={3}
                mb={4.125}
                mt={{xs: 4.75, sm: 0}}
                px={{xxl: 16}}
            >
                <Grid item container variant="article" direction="column" xs>
                    {dates.map((date, i) =>
                        <Fragment key={date}>
                            <Grid item>
                                <TypographyDate date={date} />
                                <Typography variant="h2">
                                    {_(`Article title #${i = l - i}`)}
                                </Typography>
                                <figure>
                                    <Image
                                        src={imgUrl(`articles/${i}/1.jpg`)}
                                    />
                                </figure>
                                <Typography
                                    variant="paragraph"
                                    component="article"
                                >
                                    {_('Article #' + i)}
                                </Typography>
                                <Button
                                    onClick={() =>
                                        navigate(`/publications/${i}/`)
                                    }
                                    variant="outlined"
                                >
                                    {_('More')}
                                </Button>
                            </Grid>
                            {i === 1 ? null : <Divider sx={{mb: 6.125}} />}
                        </Fragment>
                    )}
                </Grid>
                <Grid
                    item
                    variant="article"
                    ml={3.74}
                    sx={({breakpoints: b}) => ({
                        [b.down('xl')]: {
                            display: 'none',
                        },
                    })}
                    xs={1}
                >
                    {dates.map((date, i) =>
                        <Fragment key={date}>
                            <TypographyDate date={date} />
                            <Typography variant="h6">
                                <RouteLink
                                    noWrap={false}
                                    to={`/publications/${i = l - i}/`}
                                    underline="none"
                                >
                                    {_('Article title #' + i)}
                                </RouteLink>
                            </Typography>
                            {--i ? <Divider sx={{my: 3.125}} /> : null}
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </LightView>
    );
};

export const articleDates = ['2021-08-13T22:33', '2022-11-27T11:15', '2023-02-20T10:20'];
