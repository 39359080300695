import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import fetch from './fetch';


const t = msgid => localStorage.getItem('lc_msg:' + msgid) || msgid;

const I18nContext = createContext(t);

const useI18n = () => useContext(I18nContext);

export const {Consumer: I18nConsumer} = I18nContext;

export const I18nProvider = ({locale, ...props}) => {
    const [updated, setUpdated] = useState();

    const trans = useCallback(
        msgid => msgid == null ? locale : (updated ? t(msgid) : ''),
        [locale, updated],
    );

    useEffect(() => {
        fetch(`/backend/i18n/${locale}/messages.json`).then(async response => {
            response.ok && Object.entries(await response.json())
                .forEach(([msgid, msgstr]) =>
                    localStorage.setItem('lc_msg:' + msgid, msgstr),
                )
            ;
            setUpdated(new Date());
        });
    }, [locale]);

    return <I18nContext.Provider value={trans} {...props} />;
};

I18nProvider.propTypes = {
    locale: PropTypes.string.isRequired,
};

/** @see {Date.toLocaleString} */
export const I18nDate = ({date, transform, ...props}) => {
    date = (new Date(date)).toLocaleString(useI18n()(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        ...props,
    });

    return transform ? transform(date) : date;
};

I18nDate.propTypes = {
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    transform: PropTypes.func,
};

export const I18nWrapper = ({
    attr = 'title', Component, dep, wstyle, ...props
}) => {
    const _ = useI18n();
    const ref = useRef();

    useEffect(() => {
        _().startsWith('en')
            || ref.current.querySelectorAll(`[${attr}]`).forEach(el =>
                el[attr] = _(el[attr]),
            )
        ;
    }, [_, attr, dep]);

    return (
        <div style={wstyle} ref={ref}>
            <Component {...props} />
        </div>
    );
};

I18nWrapper.propTypes = {
    attr: PropTypes.string,
    Component: PropTypes.elementType.isRequired,
    dep: PropTypes.any,
    wstyle: PropTypes.object,
};

export default useI18n;
